<template>
  <svg
    class="map country timor-leste"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 271.4 295.3"
  >
    <g class="hover">
      <path
        d="M211.2,133l1.2,5,4-2.4,1.4,2.7,2.3,2.5-1.1,2.9.2,5.5.2,3.2,1.3.8.4,5.5-1.2,3.3.8,4.3,5.4,3.4,3.2,3,3.2,2.8-1.1,1.6,2.3,4,.5,7,2.6-1.4,1.5,2.7,1.6-.9-.7,6.8,2.9,3.9,1.9,2.4,2.8,5.2V212l-1,3.7-1.8,3.9.7,5.5-2.5,5.7-2.1,3-3.8,5.7-1.5,3.7-3.1,4.6-5,5.8-5.5,3.2-4.4,4.9-3.3,3.2-4.4,5.5-3.7,3.2-3.9,4.8-3.1,4.4-.8,2.1-4.3,2.2-6.1.2-6.3,2.7-3.8,2.4-4.8,2.8-2.7-2.9-2.6-1.1,2.6-3.3-3.5,1.2-7.2,4.6-3.6-1.7-2.4-1-2.8-.5-4.1-1.8-1.3-4,1.5-4.8.4-3.3-1.4-2.6-4.7-.7,3.2-3.2.9-4.7-4.7,4.4-5.3,1.2,4.5-3.5,2.5-3.7,3.5-3.2,1.6-4.7-6.8,5.4-4.3,2.2-4.3,5.1-3-2.6,1.7-3.4-1.4-4.7-1.8-2.4,1.6-1.5-5.3-3.9-3.8-.1-4-3.2-9.9.6-7.9,2.3-6.9,2.2-5-.4-7,3.3-5.3,1.4L71,259.6l-3,2.6-4.7.2-3.6.5-4.2-1.1-4.2.7-3.8.3L43,266.2l-1.5-.3-3.4,1.8-3.4,2-3.8-.2H27.4l-4.1-4.1-2.4-1.2,1.5-3.7,2.9-.9,1.5-1.4.6-2.3,2.3-4.5.7-3.8-.7-6.5.2-3.7,1.4-3.6-.9-4.2.3-1.9L29,225.1l.7-5L27.8,215l-.1-2.7,1.8,2.8-.5-6,2.3,1.9,1.1,2.5.6-3.3-1.6-5.1-.1-2-.8-1.9,1.3-3.7,1.5-1.6,1.3-3.2.1-3.8,3.1-4.6-.4,4.9,3.1-4.4,4.9-2.2,3.2-2.7,4.7-2.4,2.6-.5,1.4.8,4.8-2.4,3.5-.7,1.1-1.4,1.5-.6,3.1.2,6.2-1.9,3.5-2.9,2-3.4,3.9-3.2.7-2.6.7-3.5,4.9-5.5,1.4,5.6,2.6-1.3-1.5-3,2.3-3.1,2.2,1.4,1.5-4.9,3.5-3.2,1.8-2.5,2.9-1.1.4-1.8,2.3.7.4-1.6,2.6-.9,2.8-.9,3.7,3,2.6,3.8h3.5l3.5.6-.7-3.5,3.5-5.1,2.7-1.7-.6-1.6,2.9-3.7,3.7-2.3,2.7.8,4.9-1.2.3-3.3-3.9-2.1,3.1-.9,3.6,1.6,2.7,2.6,4.5,1.6,1.7-.6,3.3,2,3.5-1.9,2,.6,1.5-1.3,2.2,3.2-2,3.5-2.5,2.6-1.9.2.3,2.5-2.2,3.2-2.5,3.2.2,1.8,3.8,3.6,4,2,2.5,2.2,3.3,3.8h1.6l2.6,1.7.5,2,4.9,2.1,4.1-2.2,1.8-3.4,1.7-2.9,1.3-3.5,2.6-5.1-.2-3.1.7-1.9-.1-3.6,1.4-4.9,1.3-1.3-.6-2.1,1.8-3.4,1.5-3.5.4-1.9,2.3-2.4,1.3,3.2-.1,4,1.3.8-.1,2.7,1.6,3.2-.1,3.7Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M50.8,15.6,53,13.2l4.6-3.6-.1,3.2-.1,4.1-2.7-.2-1.1,2.2-2.8-3.3Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M85.2,110.4l-2.4.1L75.7,106l5.4-1.3,2.8,2,1.8,1.9Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M110,101.7l.5,1.3-.1,1.9-4.1,4.8-5,1.4-.6-.7.7-2.2,2.8-3.9Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M70.4,96.6l1.9,1.7,3.6-.5,1.2,2.7-6.7,1.3-3.9.9-3.1-.1,2.2-3.7h3.2Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M98.7,96.6l-1.1,3.6L89,102l-7.5-.8.2-2.4,4.6-1.3,3.4,1.9,3.8-.5Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M18.5,88.1l10.8.7,1.4-2.7L41,89.2l1.8,4.2,8.4,1.2,6.7,3.8-6.6,2.4-6.1-2.6-5.1.2-5.8-.5-5.2-1.1-6.4-2.5-4.1-.6-2.4.8L6,91.8l-.8-2.7L.1,88.6l4.2-6.1,6.8.4,4.4,2.5,2.4.5Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M166.4,84.5l-3.2,4.4-.2-4.8,1.1-2.3,1.3-2.2,1.2,1.9Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M125,66.8,122.8,69,119,67.8,118,65l5.7-.3Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M143.4,64.5l1.8,4.9-4.6-2.7-4.7-.5-3.3.4-3.9-.2,1.5-3.5,7-.3Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M203.2,61.3l-1,20.9-1.6,21L196,97.9l-5.6-1.3-1.5,1.8-7.2.2,2.8-5.2,3.7-1.8-1-7-2.3-5.3-10.7-5.5-4.6-.5-8.3-6-1.8,3.2-2.2.5-1.1-2.3.1-2.8-4.2-3.2,6.2-2.3,4,.1-.4-1.7h-8.3L151.4,55l-5-1.2-2.3-3.2,7.6-1.5,2.9-2.1,9.1,2.6.9,2.4,1.3,10.4,5.7,3.8,5-6.8,6.6-3.8h5l4.8,2.2,4.1,2.3Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M114.1,35.5l-4.5,6.4-4.3,1.2-5.4-1.2-9.5.3-4.9.9L84.7,48l5,5.7,3.1-2.9,10.6-2.2-.5,2.9-2.5-.9-2.5,3.8-5.1,2.5,5.1,8.2-1.1,2.2,4.8,7.4-.3,4.2-3.1,1.9-2.1-2.3,3-5.2-5.7,2.5L92.1,74l.8-2.5L89,67.7l.7-6.2-3.9,1.9.2,7.5-.2,9.2-3.7.9-2.3-1.8,1.9-5.9-.6-6.2L78.7,67l-1.6-4.4,2.5-4.2.9-5.1,3-9.7L84.6,41l4.8-4.8,4.5,1.9,7.1.9,6.5-.3,5.6-4.6Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M133.7,37.4l-.3,5.6-2.9-.7-.9,3.9,2.3,3.4-1.6.8-2.2-4.1-1.7-8.2,1-5.1,1.8-2.3.5,3.5,3.4.5Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M25.7,31.7l1,4.3,3.9,3.7,3.7-1.3,3.6.4,3.3-3.2,2.7-.6,5.4,1.8,4.6-1.4,2.6-8.9,2.1-2.2L60.3,17h6.5l5,1.1-3,5.8,4.4,6-.9,3,6.4,5.9-6.7.8L70.2,44l.2,5.8-5.5,4.4-.4,6.4L62,70.4l-.7-2.3L54.7,71l-2.1-3.9-4-.4-2.8-2.1L39,67l-1.9-3.2-3.8.4-4.6-.7-.6-8.6-2.8-1.8-2.7-5.5L21.8,42l.6-6Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M71.8,18.1l-5-1.1H60.3l-1.7,7.3-2.1,2.2-2.6,8.9-4.6,1.4L43.9,35l-2.7.6-3.3,3.2-3.6-.4-3.7,1.3L26.7,36l-1-4.3,4.2,2.2,4.3-1.2,1-5.4,2.4-1.2,6.8-1.4,3.8-5.1,2.6-4,2.8,3.3,1.1-2.2,2.7.2.1-4.1.1-3.2,4.1-4.4,2.6-5L66.6.1l3.1,3.3.4,2.8L73.9,8l4.8,1.9-.2,2.5-3.8.3,1.1,3.2Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M264.4,79.8l-3,.5-1,1.8-3.2,1.6-3,1.5h-3.1l-4.5-1.9-3.1-1.8.6-2,5,1,3.2-.5,1-3.1.9-.2.3,3.4,3.2-.4,1.8-2.2,3.3-2.3-.4-3.8,3.4-.1,1.1,1-.4,3.6Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M200.6,103.2l1.6-21,1-20.9,9.6,4.4,10.3,3.7,3.7,3.3,3,3.2.6,3.8,9.2,4,1.1,3.4-5.2.7.9,4.3,4.7,4.2,3,6.8,3.3-.2-.6,2.8,4.3,1.1-1.8,1.2,5.7,2.7-.9,1.9-3.8.4-1.2-1.6-4.7-.8-5.6-.9-4-4.1L232,102l-2.5-5.6-7.1-2.8-4.9,1.8-3.7,2.2.3,4.7-4.6,2.2-3.1-1.1Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M271.3,73.5l-1.8,1.7-.9-3.8L267.5,69,265,66.9l-3.1-2.8-4-1.9,1.6-1.5,3,1.8,1.9,1.4,2.3,1.5,2.1,2.7,2.1,2.1Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        :class="`hover timor-leste ${isActive('Timor-Leste')}`"
        data-loc="228,185"
        @click="quickViewShop('timor-leste')"
        d="M110.4,104.9l.1-1.9-.5-1.3.8-1.5,4.9-1.4,4-.3,1.8-.8,2.1.8-2.2,1.8-6.1,2.8-4.9,1.8Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
    </g>
    <g
      class="pin"
      :transform="`translate(${targetLoc[0]} ${targetLoc[1]}) scale(1)`"
    >
      <path
        d="M-102.2-109.8c-1.4-1.7-3.4-2.9-5.5-3.3c-5.3-1.2-10.6,2.2-11.8,7.4c-0.2,0.8-0.3,1.7-0.2,2.5c-0.2,5.2,3.9,10,7.7,15 c0.9,1.2,2.2,2.4,2.3,2.6l2.1-2.5C-102.9-94.2-96-103.2-102.2-109.8z M-105-102c-0.5,2.6-3.1,4.3-5.7,3.9h-0.1 c-2.6-0.6-4.3-3.2-3.8-5.9c0.5-2.6,3.1-4.4,5.7-3.8c0,0,0,0,0.1,0C-106.2-107.2-104.5-104.6-105-102z"
      />
      <path
        class="st1"
        d="M-108.2-110.4c-4-0.8-7.9,1.7-8.7,5.7c0,0,0,0.1,0,0.1c-0.9,4,1.6,8,5.7,9h0.2c4,0.7,7.8-1.9,8.6-5.9 C-101.6-105.4-104.2-109.4-108.2-110.4z M-105-102c-0.5,2.6-3.1,4.3-5.7,3.9h-0.1c-2.6-0.6-4.3-3.2-3.8-5.9 c0.5-2.6,3.1-4.4,5.7-3.8c0,0,0,0,0.1,0C-106.2-107.2-104.5-104.6-105-102z"
        fill="#fff"
      />
      <text :x="`-90`" y="-95" class="small">
        {{ country }}
      </text>
    </g>
    <text
      x="228"
      y="185"
      :class="`small ${isActive('Timor-Leste')}`"
      style="font-size: 16px"
    >
      Timor-Leste
    </text>
  </svg>
</template>
<script>
export default {
  name: "Mexico",
  data() {
    return {
      targetLoc: [0, 0],
    };
  },
  props: {
    country: String,
  },
  mounted() {
    const target = document.querySelector(".map .active");
    this.targetLoc = [
      target.dataset.loc.split(",")[0],
      target.dataset.loc.split(",")[1],
    ];
    this.log("Map:#901", "Country", this.country, target);
  },
  methods: {
    isActive(query) {
      return query === this.country ? "active" : "";
    },
    quickViewShop(selection) {
      if (selection !== "shop") {
        this.$store.commit("saveFilterInStore", [selection]);
        this.$store.commit("saveActiveFilters", [1, 0, 0, 0]);
      }
      window.location = "#/user/shop";
    },
  },
};
</script>
